<template>
  <div class="page-contain my-table-list">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="客户名称:">
              <el-input size="small" v-model="searchForm.consigneeName" placeholder="请输入客户名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="销售日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否确认:">
              <el-select size="small" v-model="searchForm.orderStatus" placeholder="请选择是否确认" clearable>
                <el-option v-for="(item, index) in salesConfirm" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
        </div>
      </div>
      <el-table 
        id="myTable"
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="我发出去的承诺达标合格证" width='190px' prop="cscUrl">
          <template slot-scope="scope">
            <my-image
              v-if="scope.row.cscUrl"
              style="height: 100px"
              :src="scope.row.cscUrl"
              :preview-src-list="[scope.row.cscUrl]">
            </my-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" v-if="scope.row.realStatus == '01'" type="text" @click="cancelOrder(scope.row, scope.$index)">取消销售</el-button>
            <el-button size="small" v-if="scope.row.realStatus == '02'" type="text" @click="comfirmOrder(scope.row, scope.$index)">确认销售</el-button>
            <el-button size="small" v-if="scope.row.realStatus == '03'" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <sale-edit ref="editForm" @getData="getData"></sale-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import saleEdit from './saleEdit.vue'
  import * as XLSX from "xlsx"
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'saleList',
    components: {
      pagination,
      breadcrumb,
      saleEdit
    },
    data() {
      let title = '我要销售'
      return {
        timeRange: '',
        loadtext: '加载中',
        salesConfirm: CONSTPARAMS.salesConfirm,
        breadStaticData:['承诺达标合格证管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          supplierId: utils.getBodyId(),
          productName: '',
          consigneeName: '',
          startTime: '',
          endTime: '',
          orderStatus: '00'
        },
        urlName: 'pspurchasesell',
        tableHead: [{
          prop: 'consigneeName',
          label: '下游客户'
        },{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'createTime',
          label: '销售时间',
          width: 150
        },{
          prop: 'practicaWeight',
          label: '销售重量',
          width: 120
        },{
          prop: 'marketPrice',
          label: '单价',
          width: 120
        },{
          prop: 'practicalMarketPrice',
          label: '金额',
          width: 120
        }],
        initForm: {
          transactionRecordId: '',
          voucherUrl: [],
          customerName: '',
          customerId: '',
          bodyId: utils.getBodyId(),
          productName: '',
          residualWeight: 0,
          weightUnit: '千克',
          practicaWeight: '',
          predictPrice: '',
          supplierName: utils.getBodyName(),
          itemCode: '',
          procurementCode: '',
          certificateOfComplianceNames: '',
          certificateOfComplianceId: '',
          certificateOfComplianceBathCode: '',
          parentFromRecordId: '',
          cscFrom: '',
          practicalMarketPrice: ''
        },
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      transData(data){
        //01采购方，02供货方
        //recorder 02 supplierStatus
        //recorder 01 consigneeStatus
        let obj = {
          '01': '已确定', 
          '02': '待确定', 
          '03': '取消'
        },
        status = ''
        if(data.recorder == '01'){
          status = data.consigneeStatus
        }else{
          status = data.supplierStatus
        }
        return obj[status] || ''
      },
      getStatus(data){
        //01采购方，02供货方
        //recorder 02 supplierStatus
        //recorder 01 consigneeStatus
        let status = ''
        if(data.recorder == '01'){
          status = data.consigneeStatus
        }else{
          status = data.supplierStatus
        }
        return status
      },
      download(){
        let html = '<table><tr><th>序号</th><th>下游客户</th><th>产品名称</th><th width="130px">销售时间</th><th>销售重量</th><th>单价</th><th>金额</th><th>我发出去的承诺达标合格证</th></tr>',
          data = this.listData
        if(!data || !data.length){
          this.message('暂无数据，无法导出！', 'warning')
          return
        }
        for(let i = 0, len = data.length; i < len;i++){
          let item = data[i]
          html += '<tr>'
          html += `<td>${i + 1}</td>`
          html += `<td>${item.consigneeName}</td>`
          html += `<td>${item.productName}</td>`
          html += `<td>${item.createTime}</td>`
          html += `<td>${item.practicaWeight}</td>`
          html += `<td>${item.marketPrice}</td>`
          html += `<td>${item.practicalMarketPrice}</td>`
          html += `<td><img style="height: 50px;width: 50px;" src="${item.cscUrl}" class="el-image__inner"></td>`
          html += '</tr>'
        }
        html += '</table>'
        utils.tableToNotIE(html, this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx')
        return
        let arr = this.listData,
        tableData = [['序号', '下游客户', '产品名称', '销售时间', '销售重量', "单价", "金额", "我发出去的承诺达标合格证"]] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', "warning")
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.consigneeName,
            item.productName,
            item.createTime,
            item.practicaWeight,
            item.marketPrice,
            item.practicalMarketPrice,
            item.cscUrl
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0])
          endTime = utils.dateFormat(val[1])
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      async initTable(){
        this.getData()
      },
      async add(){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          certificateOfComplianceId: id
        }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', obj)
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.sellfind({
          transactionRecordId: data.transactionRecordId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }

            result = {
              ...result,
              voucherUrl: result.voucherUrl ? result.voucherUrl.split(',') : [],
              cscUrl: result.cscUrl ? result.cscUrl.split(',') : [],
              customerName: result.consigneeName || '',
              predictPrice: result.marketPrice || '',
              certificateOfComplianceNames: `${utils.dateFormat(result.issueTime, 'yyyy-MM-dd') ? utils.dateFormat(result.issueTime, 'yyyy-MM-dd') + '/' : ''}${result.productName || ''}/${result.allWeight || '0'}${result.allWeightUnit || ''}`
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      cancelOrder(data, index){
        let _this = this
        _this.$confirm(`确认要取消销售${data.consigneeName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.pspurchasesellsellupdatestatus({
            supplierStatus: '03',
            transactionRecordId: data.transactionRecordId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('取消销售成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      comfirmOrder(data, index){
        let _this = this
        _this.$confirm(`确认要确认销售${data.consigneeName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.pspurchasesellsellupdatestatus({
            supplierStatus: '01',
            transactionRecordId: data.transactionRecordId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('确认销售成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.consigneeName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.commonpcselldeleteorder({
            supplierId: utils.getBodyId() || '',
            transactionRecordId: data.transactionRecordId || ''
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                supplierStatusName: _this.transData(item),
                realStatus: _this.getStatus(item),
                practicaWeight: (item.practicaWeight || '') + (item.weightUnit || '')
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>