<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="130px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售客户：" prop="customerName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.customerName }}</p>
                <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.customerName" placeholder="请选择销售客户">
                  <el-button slot="append" @click="chooseType('getclientlist')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品合格证：" prop="certificateOfComplianceNames">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.certificateOfComplianceNames }}</p>
                <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.certificateOfComplianceNames" placeholder="请选择产品合格证">
                  <el-button slot="append" @click="chooseType('getcertificate')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="产品名称：" prop="productName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productName }}</p>
                <el-input clearable v-else :disabled="true" v-model="ruleForm.productName" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <p v-if="editType != 'look'" class="tips-red pt10 pb10">合格证产品剩余销售量：{{ (ruleForm.residualWeight || 0)  + (ruleForm.weightUnit || '')}}</p>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售产品重量：" prop="practicaWeight">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.practicaWeight || '') + (ruleForm.weightUnit || '')}}</p>
                <el-input clearable v-else @input="practicaWeightChange" v-model="ruleForm.practicaWeight" placeholder="请输入销售产品重量" maxlength=14 show-word-limit class="input-with-select">
                  <template slot="append">{{ ruleForm.weightUnit }}</template>
                  <!-- <el-select v-model="ruleForm.weightUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.weight"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售价格：" prop="predictPrice">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.predictPrice }}</p>
                <el-input clearable v-else @input="predictPriceChange" v-model="ruleForm.predictPrice" placeholder="请输入销售价格"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售金额：" prop="practicalMarketPrice">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.practicalMarketPrice }}</p>
                <el-input clearable v-else @input="practicalMarketPriceChange" v-model="ruleForm.practicalMarketPrice" placeholder="请输入销售金额"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售单/销售任证：" prop="voucherUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.voucherUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.voucherUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.voucherUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editType == 'look'">
            <el-col :span="24">
              <el-form-item label="我发出去的承诺达标合格证：" prop="cscUrl" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.cscUrl"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.cscUrl">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.cscUrl.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics('cscUrl')" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitAddNew()">保存后复制新增</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="105001" :businessCode="ruleForm.transactionRecordId" :imgSize="0.5" propName="voucherUrl" storeTableName="purchaseDocumentDirectoryTable" @getpics="getpics"></pics>
    <clientList ref="getclientlist" @getclientlist="getclientlist"></clientList>
    <certificate ref="getcertificate" @getcertificate="getcertificate"></certificate>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import clientList from '@/views/common/clientList.vue'
import certificate from '@/views/common/certificate.vue'
import pics from '@/views/common/pics.vue'
let _t = null
export default {
  name: "saleEdit",
  components: {
    pics,
    clientList,
    certificate
  },
  props:{
  },
  data() {
    return {   
      rules: {
        customerName: [{ required: true,  trigger: "change", message: '请选择销售客户'}],
        certificateOfComplianceNames: [{ required: true,  trigger: "change", message: '请选择产品合格证'}],
        practicaWeight: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入销售产品重量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入正确的数字"))
            }else{
              let resWeight = _t.ruleForm.residualWeight
              if(resWeight < val){
                callback(new Error("销售产品重量不能大于产品剩余收购量"))
              }else{
                callback()
              }
            }
          }
        }],
        predictPrice: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入正确的数字"))
            }else{
              callback()
            }
          }
        }],
        practicalMarketPrice: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入正确的数字"))
            }else{
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      selectObj: {
        weight: CONSTPARAMS.saleUnit
      },
    };
  },
  created() {
    this.getSelectData()
	},
  mounted() {
    _t = this
  },
  methods: {
    practicaWeightChange(val){
      if(isNaN(val)) return
      let price = this.ruleForm.predictPrice
      this.ruleForm.practicalMarketPrice = utils.getFixedNumber(price * val)
      this.$forceUpdate()
    },
    predictPriceChange(val){
      if(isNaN(val)) return
      let resWeight = this.ruleForm.practicaWeight
      this.ruleForm.practicalMarketPrice = utils.getFixedNumber(resWeight * val)
      this.$forceUpdate()
    },
    practicalMarketPriceChange(val){
      if(isNaN(val)) return
      let resWeight = this.ruleForm.practicaWeight
      if(resWeight){
        this.ruleForm.predictPrice = utils.getFixedNumber(val / resWeight)
      }
      this.$forceUpdate()
    },
    setunitQualification(val){
      let datas = this.selectObj.unitQualification || [],
        names = []
      val.forEach(item => {
        datas.forEach(cur => {
          if(cur.unitQualificationFileId == item) names.push(cur.unitQualificationFileName)
        })
      })
      this.ruleForm.productAuth.unitQualificationFileName = names.join(',')
      this.ruleForm.productAuth.unitQualificationFileId = val.join(',')
    },
    async getSelectData(){
      let data = await utils.getUrlDatas('commonunitqualificationfilelist', {bodyId: utils.getBodyId()})
      this.selectObj.unitQualification = data || []
    },
    chooseType(name){
      this.$refs[name].initData()
    },
    getcertificate(cont){
      let name = `${utils.dateFormat(cont.issueTime, 'yyyy-MM-dd') || ''}/${cont.productName || ''}/${cont.allWeight || '0'}${cont.weightUnit || ''}`

      this.ruleForm.certificateOfComplianceNames = name || ''
      this.ruleForm.certificateOfComplianceId = cont.certificateOfComplianceId || ''
      this.ruleForm.certificateOfComplianceBathCode = cont.certificateOfComplianceBathCode || ''
      this.ruleForm.productName = cont.productName || ''
      this.ruleForm.parentFromRecordId = cont.transactionRecordId || ''
      this.ruleForm.cscFrom = cont.cscFrom || ''
      // this.findsellprice(cont.productId)
      this.setcertificate(cont.productId, cont.certificateOfComplianceBathCode, cont.cscFrom || '', cont.transactionRecordId || '')
    },
    setcertificate(productId, id, cscFrom, transactionRecordId){
      let _this = this
      request.psfindresidualweight({
        certificateOfComplianceBathCode: id || '',
        parentFromRecordId: transactionRecordId || '',
        cscFrom: cscFrom || '',
      }).then(res => {
        if(res.code == 200){
          _this.ruleForm.residualWeight = res.data?.residualWeight || 0
          _this.ruleForm.weightUnit = res.data?.weightUnit || ''
          _this.ruleForm.practicaWeight = res.data?.residualWeight || 0
          _this.findsellprice(productId)
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    findsellprice(productId){
      let _this = this
      request.findsellprice({
        bodyId: utils.getBodyId() || '',
        productId: productId || '',
      }).then(res => {
        if(res.code == 200){
          _this.ruleForm.predictPrice = res.data || 0
          _this.predictPriceChange(res.data || 0)
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getclientlist(data){
      this.ruleForm.customerName = data.customerName || ''
      this.ruleForm.customerId = data.customerId || ''
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.voucherUrl)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = ruleForm
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    submitAddNew(){
      this.submitForm(true)
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'commonsavesellorder' : 'commonproductupdate',
            target = {
            ..._this.ruleForm,
            voucherUrl: _this.ruleForm.voucherUrl?.join(',') || '',
            practicalMarketPrice: parseFloat(_this.ruleForm.practicalMarketPrice || 0),
            practicaWeight: parseFloat(_this.ruleForm.practicaWeight || 0),
            residualWeight: parseFloat(_this.ruleForm.residualWeight || 0),
            predictPrice: parseFloat(_this.ruleForm.predictPrice || 0)
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.transactionRecordId = newId
              }else{
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .mr100{
    margin-left: 70px;
  }
</style>


