<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <client ref="client" @getclient="getclient" :titleName="titleName" :tempType="tempType" :isDialog="true"></client>
  </el-dialog>
</template>
<script>
  import client from '@/views/common/client.vue'
  export default {
    name: 'clientList',
    components: {
      client
    },
    props:{
      tempType: {
        type: String,
        default: '01'
      },
      titleName: {
        type: String,
        default: '客户'
      }
    },
    data() {
      return {
        closeFlag: false,
        loading: false,
        title: '选择客户',
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
      initData(){
        this.closeFlag = true
        this.$refs.client && this.$refs.client.reTable()
      },
      closeModel() {
        this.closeFlag = false
      },
      getclient(data){
        this.$emit('getclientlist', data)
        this.closeModel()
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>